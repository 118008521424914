@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-toastify/dist/ReactToastify.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  height: 100vh;
}

.page-wrapper {
  height: 100vh;
  padding: 86px 64px;
  /* padding-bottom: 64px; */
}

.appbar-wrapper {
  padding: 16px 64px;
}

@media (max-width: 639px) {
  .page-wrapper {
    height: 100vh;
    padding: 86px 32px;
    /* padding-bottom: 64px; */
  }
  .appbar-wrapper {
    padding: 16px 32px;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.basic-hover:hover {
  cursor: pointer;
  border-radius: 4px !important;
  background-color: rgb(226, 226, 226) !important;
}
