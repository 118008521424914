.pagination {
  display: flex;
  gap: 8px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.pagination li {
  width: 40px;
  height: 40px;
  display: flex;
  font-size: medium;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  border-radius: 4px;
}

.pagination li:hover {
  background-color: rgb(237, 237, 237);
}

.pagination li.active {
  background-color: orangered;
  color: white;
}

.pagination li:hover.active {
  background-color: rgb(198, 53, 0);
  color: white;
}

.pagination a {
  text-decoration: none;
}
.pagination .active {
  background-color: #dedede;
}
